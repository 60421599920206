import React, { useRef } from 'react'
import moment from 'moment'

import cn from 'classnames'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Card from '../../../design_system/card/card'
import Tag from '../../../design_system/tag/tag'
import ShareIcons from '../../../small_views/share_icons/share_icons'

import classes from './article.module.css'

const Article = ({
	className,
	type,
	title = 'Titre inconnu',
	content = 'Contenu inconnu',
	preview,
	tags,
	publishedAt,
	id,
	children
}) => {
	const showShareIconsSeparatly = useMediaQuery('(max-width: 480px)', {
		defaultMatches: true
	});
	const isBlog = type === 'blog'
	const tagsNode = (
		<TagsShareIcons
			{...{
				tags,
				id,
				publishedAt,
				showShareIconsSeparatly,
				isBlog
			}}
		/>
	)
	const link = `https://karnott.thomasgrivet.fr/blog-post/${id}`
	return (
		<div className={cn(classes.container, classes[type], className)}>
			<div className={classes.headerContent}>
				{type === 'actuality' && tagsNode}
				<div className={classes.title}>{title}</div>
				{type === 'blog' && tagsNode}
				<Card className={classes.previewCard}>
					<img
						className={classes.preview}
						src={preview}
						alt={`${id} Visuel'`}
					/>
				</Card>
				{showShareIconsSeparatly && isBlog && (
					<ArticleShareIcons {...{ showShareIconsSeparatly, link }} />
				)}
			</div>
			<div className={classes.content}>
				<div className={classes.articleContentText}>{content}</div>
				{children}
			</div>
		</div>
	)
}

const TagsShareIcons = ({
	isBlog,
	link,
	publishedAt,
	tags,
	showShareIconsSeparatly
}) => {
	const momentDate = useRef(moment(publishedAt))
	let newTags = null
	if (!isBlog) {
		newTags = useRef([
			{ label: momentDate.current.format('DD MMMM YYYY'), color: '#8b6706' }
		])
	} else {
		newTags = useRef([
			{ label: momentDate.current.format('MMMM'), color: '#8b6706' },
			{ label: momentDate.current.format('YYYY'), color: '#8cc047' },
			...tags
		])
	}
	return (
		<div className={classes.tagsShareIconsContainer}>
			<div className={classes.tagsContainer}>
				{newTags.current.map(tag => (
					<Tag className={classes.tag} key={`tag_${tag.label}`} {...tag} />
				))}
			</div>
			{isBlog && !showShareIconsSeparatly && (
				<ArticleShareIcons {...{ link }} />
			)}
		</div>
	)
}

const ArticleShareIcons = ({ showShareIconsSeparatly, link }) => (
	<div
		className={cn(
			classes.shareIconsContainer,
			showShareIconsSeparatly && classes.wrappedShareIconsContainer
		)}
	>
		<ShareIcons
			text={`Découvrez cet article : ${link}`}
			{...showShareIconsSeparatly && {
				iconClassName: classes.coloredShareIcon
			}}
			{...{ link }}
		/>
	</div>
)

export default Article
