import React, { cloneElement, useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';
import { animated, config, useSpring } from 'react-spring';
import { Location } from '@reach/router'

import Portal from '@material-ui/core/Portal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import classes from './drawer.module.css';

const ANIMATED_INDEX_ATTRIBUTE = 'data-drawer-animated-index';

const getDirectAnimatableChildrenFromDrawer = (drawerNode) => {
	if (!drawerNode) {
		return null;
	}
	return Array.from(drawerNode.querySelectorAll(`*[${ANIMATED_INDEX_ATTRIBUTE}]`)).sort(
		(a, b) => (a.getAttribute(ANIMATED_INDEX_ATTRIBUTE) || 0) - (b.getAttribute(ANIMATED_INDEX_ATTRIBUTE) || 0)
	);
};

const Drawer = ({
	anchor = 'left', open, onClose, location, children, supClasses = {}
}) => {
	const drawerReference = useRef();
	const overflowTimeout = useRef();
	useEffect(() => {
		document.body.style.overflow = 'auto';
	}, []);

	useEffect(() => {
		if (overflowTimeout.current) {
			clearTimeout(overflowTimeout.current);
		}
		overflowTimeout.current = setTimeout(() => {
			document.body.style.overflow = open ? 'hidden' : 'auto';
		}, 500);
	}, [open, overflowTimeout.current]);

	const oldPathname = useRef();
	useEffect(() => {
		if (!location || !location.pathname) {
			return;
		}
		const { pathname } = location;
		if (pathname !== oldPathname.current) {
			if (onClose) {
				onClose();
			}
			oldPathname.current = pathname;
		}
	}, [location]);
	useEffect(() => {
		if (drawerReference && drawerReference.current) {
			const animatableChildren = getDirectAnimatableChildrenFromDrawer(drawerReference.current);
			if (!animatableChildren) {
				return;
			}
			animatableChildren.forEach((element, index) => {
				if (open) {
					Object.entries({
						opacity: 0,
						animation: `fade-in-translate-${anchor}-50 1s`,
						animationFillMode: 'forwards',
						animationDelay: `${index * 50}ms`
					}).forEach(([key, value]) => {
						element.style[key] = value;
					});
				} else {
					Object.keys(element.style).forEach((key) => {
						element.style.removeProperty(key);
					});
				}
			});
		}
	}, [open, drawerReference && drawerReference.current]);
	const closedMenuStyles = {
		translation: anchor === 'right' ? 0 : -100,
		pointerEvents: 'none'
	};
	const openedMenuStyles = {
		translation: anchor === 'right' ? -100 : 0,
		pointerEvents: 'all'
	};
	const menuStyles = useSpring({
		...(!open && closedMenuStyles),
		...(open && openedMenuStyles),
		config: config.default
	});
	const parentContainerStyles = useSpring({
		opacity: open ? 1 : 0,
		pointerEvents: open ? 'all' : 'none'
	});
	const { opacity, translation, pointerEvents } = menuStyles;
	const handleClose = useCallback(() => {
		if (!open) {
			return;
		}
		if (typeof onClose === 'function') {
			onClose();
		}
	}, [open, onClose]);
	return (
		<Portal>
			<animated.div className={classes.container} style={parentContainerStyles}>
				<ClickAwayListener onClickAway={handleClose}>
					<animated.div
						ref={drawerReference}
						className={cn(
							classes.drawer,
							anchor === 'right' && classes.rightAnchoredDrawer,
							supClasses.drawer
						)}
						style={{
							opacity,
							pointerEvents,
							transform: translation.interpolate(value => `translate3d(${value}%, 0, 0)`)
						}}
					>
						{React.Children.map(children, child =>
							cloneElement(child, {
								drawerAnchor: anchor
							}))}
					</animated.div>
				</ClickAwayListener>
			</animated.div>
		</Portal>
	);
};

const WithLocationDrawer = props => (
	<Location>
		{({ location }) => <Drawer {...{ location }} {...props} />}
	</Location>
);

export default WithLocationDrawer;
