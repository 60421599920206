import BonduelleLogo from '../../../../../assets/images/trusting_customers/k-logo-bonduelle.jpg';
import AmazoneLogo from '../../../../../assets/images/trusting_customers/k-logo-amazone.jpg';
import VaderstadLogo from '../../../../../assets/images/trusting_customers/k-logo-vaderstad.jpg';
import CreditAgricoleLogo from '../../../../../assets/images/trusting_customers/k-logo-creditagricole.jpg';
import LemkenLogo from '../../../../../assets/images/trusting_customers/k-logo-lemken.jpg';
import KuhnLogo from '../../../../../assets/images/trusting_customers/k-logo-kuhn.jpg';
import BerthoudLogo from '../../../../../assets/images/trusting_customers/k-logo-berthoud.jpg';
import DaucyLogo from '../../../../../assets/images/trusting_customers/k-logo-daucy.jpg';
import RousseauLogo from '../../../../../assets/images/trusting_customers/k-logo-rousseau.jpg';
import MaisadourLogo from '../../../../../assets/images/trusting_customers/k-logo-maisadour.jpg';
import MaschioLogo from '../../../../../assets/images/trusting_customers/k-logo-maschio.jpg';
import HorschLogo from '../../../../../assets/images/trusting_customers/k-logo-horsch.jpg';
import CarreLogo from '../../../../../assets/images/trusting_customers/k-logo-carre.jpg';
import IsagriLogo from '../../../../../assets/images/trusting_customers/k-logo-isagri.jpg';
import MasseyFergussonLogo from '../../../../../assets/images/trusting_customers/k-logo-masseyfergusson.jpg';
import CumaLogo from '../../../../../assets/images/trusting_customers/k-logo-cuma.jpg';
import LaCampageLogo from '../../../../../assets/images/trusting_customers/k-logo-lacampagne.jpg';
import GregoireLogo from '../../../../../assets/images/trusting_customers/k-logo-gregoire.jpg';

const trustingCustomersData = Object.freeze({
	bonduelle: {
		picture: BonduelleLogo
	},
	amazone: {
		picture: AmazoneLogo
	},
	vaderstad: {
		picture: VaderstadLogo
	},
	creditAgricole: {
		picture: CreditAgricoleLogo
	},
	lemken: {
		picture: LemkenLogo
	},
	kuhn: {
		picture: KuhnLogo
	},
	berthoud: {
		picture: BerthoudLogo
	},
	daucy: {
		picture: DaucyLogo
	},
	rousseau: {
		picture: RousseauLogo
	},
	maisadour: {
		picture: MaisadourLogo
	},
	maschio: {
		picture: MaschioLogo
	},
	horsh: {
		picture: HorschLogo
	},
	carre: {
		picture: CarreLogo
	},
	isagri: {
		picture: IsagriLogo
	},
	masseyFergusson: {
		picture: MasseyFergussonLogo
	},
	cuma: {
		picture: CumaLogo
	},
	laCampagne: {
		picture: LaCampageLogo
	},
	gregoire: {
		picture: GregoireLogo
	}
});

export default trustingCustomersData;
