import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import Popper from '@material-ui/core/Popper';
import { animated, config, useSpring } from 'react-spring';

import Card from '../card/card';

import classes from './popper_card.module.css';

const PopperCard = ({
	className, anchorElement, open, onClose, popperProps, ...other
}) => {
	const { opacity, pointerEvents, translation } = useSpring({
		opacity: open ? 1 : 0,
		pointerEvents: open ? 'all' : 'none',
		translation: open ? 0 : 20,
		config: config.default
	});
	const getTranslationInterpolationFromPlacement = useCallback((value) => {
		const placement = (popperProps && popperProps.placement) || 'bottom';
		if (['top', 'bottom'].some(key => placement === key)) {
			return `translate3d(0, ${value}px, 0)`;
		}
		return `translate3d(-${value}px, 0, 0)`;
	});
	return (
		<Popper
			open
			className={cn(classes.popper, !open && classes.closedPopper)}
			anchorEl={anchorElement}
			{...popperProps}
		>
			<animated.div
				className={classes.wrapper}
				style={{
					opacity,
					pointerEvents,
					transform: translation.interpolate(getTranslationInterpolationFromPlacement)
				}}
			>
				<Card className={cn(className, classes.container)} {...other} />
			</animated.div>
		</Popper>
	);
};

export default PopperCard;
