import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import TextField from '../../../../design_system/text_field/text_field';
import Button from '../../../../design_system/button/button';
import DownloadsLinks from '../../../../small_views/downloads_links/downloads_links';
import SocialIcons from '../social_icons/social_icons';

import sections from './footer_links_data';

import classes from './footer_links.module.css';

const FooterLinks = () => (
	<div
		className={classes.container}
	>
		<div className={classes.sectionsDownloadLinks}>
			<div className={classes.sectionsContainer}>
				{Object.entries(sections).map(([sectionId, section]) => (
					<Section
						key={`footer_links_${sectionId}`}
						{...section}
					/>
				))}
			</div>
			<DownloadsLinks
				className={classes.downloadLinks}
				linkClassName={classes.downloadLink}
			/>
		</div>
		<Section
			type="NEWSLETTER_SUBSCRIPTION"
			name="Recevez dès maintenant toute notre actualité en vous inscrivant à notre newsletter"
		/>
	</div>
)

const Section = ({ name, type, children }) => {
	if (!name) {
		return null;
	}
	const isNewsletterType = type === 'NEWSLETTER_SUBSCRIPTION';
	return (
		<div className={cn(classes.section, isNewsletterType && classes.newsletterSection)}>
			<div className={classes.sectionName}>
				{name}
			</div>
			{children && <SectionChildren {...{ children }} />}
			{isNewsletterType && <NewsletterSectionContent />}
		</div>
	);
}

const SectionChildren = ({ children }) => {
	if (!children) {
		return null;
	}
	return (
		<div className={classes.sectionChildrenContainer}>
			{Object.entries(children).map(([childId, { text, link, linkType }]) => {
				if (!text) {
					return null;
				}
				let node = (
					<span className={classes.sectionChildText}>
						{text}
					</span>
				);
				if (link) {
					if (linkType === 'gatsbyLink') {
						node = (
							<Link
								className={classes.sectionChildLink}
								to={link}
							>
								{node}
							</Link>
						);
					} else {
						node = (
							<a
								className={classes.sectionChildLink}
								href={link}
								target="_blank"
								rel="noreferrer noopener"
							>
								{node}
							</a>
						);
					}
				}
				return (
					<div
						key={`section_child_${childId}`}
						className={classes.sectionChild}
					>
						{node}
					</div>
				);
			})}
		</div>
	);
}

const NewsletterSectionContent = () => {
	const showShortLabel = useMediaQuery('(max-width: 440px)', { defaultMatches: true });
	const [email, setEmail] = useState('');
	const handleInputChange = useCallback(e => setEmail(e.currentTarget.value));
	const label = showShortLabel
		? '>'
		: 'Envoyer >';
	return (
		<div className={classes.newsletterAndSocialLinks}>
			<div className={classes.newsletterSectionContent}>
				<TextField
					className={classes.newsletterSubscriptionTextField}
					value={email}
					onChange={handleInputChange}
					placeholder="Saisir mon adresse email"
				/>
				<Button
					className={classes.button}
					variant="outlined"
					style={{ color: '#fff' }}
				>
					{label}
				</Button>
			</div>
			<SocialIcons
				className={classes.socialIcons}
				iconContainerClassName={classes.socialIconContainer}
			/>
		</div>
	);
}

export default FooterLinks;
