import { useCallback, useEffect, useState } from 'react';

const useScrollCallback = (positionY = 0, callback) => {
	const [hasScrolled, setHasScrolled] = useState(false);

	const handleScrollEvent = useCallback(() => {
		const { scrollY } = window;
		if (scrollY > positionY) {
			if (typeof callback === 'function') {
				callback();
			}
			if (!hasScrolled) {
				setHasScrolled(true);
			}
		} else if (scrollY <= positionY && !hasScrolled) {
			setHasScrolled(false);
		}
	}, [hasScrolled, callback, positionY]);

	useEffect(() => {
		handleScrollEvent();
		window.addEventListener('scroll', handleScrollEvent);
		window.addEventListener('touchmove', handleScrollEvent);
		return () => window.removeEventListener('scroll', handleScrollEvent);
	}, [positionY]);

	useEffect(() => handleScrollEvent, []);

	return hasScrolled;
}

export default useScrollCallback;
