import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ImportantActualityPopup from '../../../small_views/important_actuality_popup/important_actuality_popup';
import SimaSticker from '../../../small_views/sima_sticker/sima_sticker';
import FakeIntercom from '../../../small_views/fake_intercom/fake_intercom';
import useScrollCallback from '../../../../hooks/use_scroll_callback'

import classes from './sticky_items.module.css';

const StickyItems = ({ className, showSimaSticker, showImportantActualityPopup }) => {
	const [openFakeIntercom, setFakeIntercomOpenState] = useState(false);
	const toggleFakeIntercomOpenState = useCallback(() => setFakeIntercomOpenState(!openFakeIntercom), [openFakeIntercom]);
	const tooSmallToShowTopElements = useMediaQuery('(max-width: 1160px)', { defaultMatches: true });
	const hasScrolled = useScrollCallback(0);
	const hideTopElements = tooSmallToShowTopElements || hasScrolled || openFakeIntercom
	const springProps = useSpring({
		opacity: hideTopElements ? 0 : 1,
		pointerEvents: hideTopElements ? 'none' : 'all'
	});
	return (
		<div
			className={cn(className, classes.container)}
		>
			{showImportantActualityPopup && (
				<ImportantActualityPopup
					component={animated.div}
					className={classes.importantActualityPopup}
					style={springProps}
				/>
			)}
			{showSimaSticker && (
				<SimaSticker
					component={animated.img}
					className={classes.simaSticker}
					style={springProps}
				/>
			)}
			<FakeIntercom
				open={openFakeIntercom}
				onClick={toggleFakeIntercomOpenState}
			/>
		</div>
	);
}

export default StickyItems;
