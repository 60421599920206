import React, { useRef } from 'react';

import cn from 'classnames';

import useScrollWithReferenceCallback from '../../../../../hooks/use_scroll_with_reference_callback';

import socialIconsData from './social_icons_data';

import classes from './social_icons.module.css';

const SocialIcons = ({ className, iconContainerClassName }) => {
	const containerReference = useRef()
	const showSection = useScrollWithReferenceCallback(containerReference, undefined, {
		transform: y => y + 150,
		substractWindowHeight: true
	});

	return (
		<div
			ref={containerReference}
			className={cn(classes.container, showSection && classes.visible, className)}
		>
				{Object.entries(socialIconsData).map(([id, { icon: Icon, link }]) => (
					<a
						key={`icon_${id}`}
						className={cn(classes.iconContainer, iconContainerClassName)}
						href={link}
						target="_blank"
						rel="noreferrer noopener"
						>
						<Icon className={classes.icon} />
					</a>
				))}
		</div>
	);
}

export default SocialIcons;
