import React, { memo } from 'react';

import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../../../design_system/section/section';
import SectionTitle from '../../../../design_system/section/section_title/section_title';
import Carousel from '../../../../design_system/carousel/carousel';

import trustingCustomersData from './trusting_customers_data';

import classes from './trusting_customers_section.module.css';

const TrustingCustomersSection = ({ setVisibleOnScroll = true, ...other }) => (
	<Section
		withClassName={classes.container}
		{...{ setVisibleOnScroll }}
		{...other}
	>
		{({ showSection }) => (
			<>
				<SectionTitle
					containerClassName={classes.titleContainer}
					className={classes.title}
					dismissUnderline={showSection}
				>
					{'Ils nous font confiance'}
				</SectionTitle>
				<TrustingCustomersList />
			</>
		)}
	</Section>
)

const TrustingCustomersList = memo(() => {
	const showAsCarousel = useMediaQuery('(max-width: 1200px)', { defaultMatches: true });
	let content = null;
	const items = Object.entries(trustingCustomersData).map(([id, item]) => {
		let content = (
			<TrustingCustomer
				key={`trusting_customer_${id}`}
				{...showAsCarousel && {
					className: classes.itemInCarousel
				}}
				{...{ id }}
				{...item}
			/>
		);
		if (showAsCarousel) {
			return (
				<div>
					{content}
				</div>
			);
		}
		return content;
	});
	if (showAsCarousel) {
		content = (
			<Carousel
				arrows
				autoplay
				draggable
				swipeToSlide
				slidesToShow={5}
				centerMode
				centerPadding={30}
				infinite
				speed={3500}
				rows={2}
				slidesPerRow={1}
				autoplaySpeed={2000}
				cssEase="linear"
				dots={false}
				responsive={[
					{
						breakpoint: 1050,
						settings: {
							slidesToShow: 4
						}
					},
					{
						breakpoint: 830,
						settings: {
							slidesToShow: 3
						}
					},
					{
						breakpoint: 630,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 430,
						settings: {
							slidesToShow: 1,
							centerPadding: 80
						}
					}
				]}
			>
				{items}
			</Carousel>
		);
	} else {
		content = items;
	}
	return (
		<div className={cn(classes.trustingCustomersContainer, showAsCarousel && classes.trustingCustomersContainerAsCarousel)}>
			{content}
		</div>
	);
});

const TrustingCustomer = ({ className, id, picture }) => (
	<div className={cn(classes.item, className)}>
		<div
			className={classes.pictureContainer}
		>
			<img
				className={classes.picture}
				src={picture}
				alt={id}
			/>
		</div>
	</div>
);

export default TrustingCustomersSection;
