import React, { useCallback, useRef } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import ParallaxLayer from '../../../../design_system/parallax_layer/parallax_layer';
import TextField from '../../../../design_system/text_field/text_field';
import Button from '../../../../design_system/button/button';
import Section from '../../../../design_system/section/section';
import OptimisedImage from '../../../../small_views/optimised_image/optimised_image';

import BackgroundImage from '../../../../../assets/images/views/footer/create_demo_account_section/background.png';
import BackgroundImageLowDef from '../../../../../assets/images/views/footer/create_demo_account_section/background_low_def.png';

import classes from './create_demo_account_section.module.css';

const CreateDemoAccountSection = () => {
	const showShortLabel = useMediaQuery('(max-width: 650px)', { defaultMatches: true });
	const updateParallaxLayerHeight = useRef(null);
	const getUpdateParallaxLayerHeight = useCallback((fn) => {
		updateParallaxLayerHeight.current = fn;
	});
	const handleOptimisedImageOnReady = useCallback(() => {
		if (typeof updateParallaxLayerHeight.current === 'function') {
			updateParallaxLayerHeight.current();
		}
	}, [updateParallaxLayerHeight.current]);
	const sendLabel = showShortLabel
		? '>'
		: 'Envoyer >';
	return (
		<Section
			setVisibleOnScroll
			withClassName={classes.container}
		>
			<ParallaxLayer
				{...{ getUpdateParallaxLayerHeight }}
			>
				<OptimisedImage
					className={classes.background}
					normal={BackgroundImage}
					lowDef={BackgroundImageLowDef}
					onReady={handleOptimisedImageOnReady}
					alt="Create Demo Account Background"
				/>
			</ParallaxLayer>
			<div className={classes.content}>
				<div className={classes.title}>
					{'Prêt pour une démo ?'}
				</div>
				<div className={classes.formRow}>
					<TextField
						className={classes.textField}
						placeholder="Saisir mon adresse e-mail"
					/>
					<Button
						className={classes.button}
						color="emerald"
						variant="contained"
					>
						{sendLabel}
					</Button>
				</div>
			</div>
		</Section>
	);
}

export default CreateDemoAccountSection;
