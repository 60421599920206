import React from 'react';

import cn from 'classnames';

import classes from './list.module.css';

const List = ({ className, ...other }) => (
	<ul
		className={cn(classes.container, className)}
		{...other}
	/>
);

export default List;
