import React from 'react'

import cn from 'classnames';

import classes from './with_gradient_layout.module.css'

const WithGradientLayout = ({ className, children }) => (
	<div className={cn(classes.container, className)}>{children}</div>
);

export default WithGradientLayout
