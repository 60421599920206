import React from 'react';

import cn from 'classnames';

import OptimisedImage from '../optimised_image/optimised_image';

import DownloadFromAppStoreImage from '../../../assets/images/common/download_from_appstore/download_from_appstore.png';
import DownloadFromAppStoreImageLowDef from '../../../assets/images/common/download_from_appstore/download_from_appstore_low_def.png';
import DownloadFromGooglePlayImage from '../../../assets/images/common/download_from_googleplay/download_from_googleplay.png';
import DownloadFromGooglePlayImageLowDef from '../../../assets/images/common/download_from_googleplay/download_from_googleplay_low_def.png';

import classes from './downloads_links.module.css';

const DownloadsLinks = ({ className, linkClassName }) => (
	<div className={cn(classes.container, className)}>
		<a
			className={classes.link}
			href="https://play.google.com/store/apps/details?id=com.karnott"
			target="_blank"
			rel="noopener noreferrer"
		>
			<OptimisedImage
				className={cn(classes.link, linkClassName)}
				normal={DownloadFromGooglePlayImage}
				lowDef={DownloadFromGooglePlayImageLowDef}
				alt="Télécharger depuis le Google Play Store"
			/>
		</a>
		<a
			className={cn(classes.link, linkClassName)}
			href="https://apps.apple.com/fr/app/karnott/id1451207222"
			target="_blank"
			rel="noopener noreferrer"
		>
			<OptimisedImage
				className={classes.downloadPicture}
				normal={DownloadFromAppStoreImage}
				lowDef={DownloadFromAppStoreImageLowDef}
				alt="Télécharger depuis l'App Store"
			/>
		</a>
	</div>
);

export default DownloadsLinks;
