import React, { forwardRef, useRef } from 'react'

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import useScrollWithReferenceCallback from '../../../hooks/use_scroll_with_reference_callback'

import classes from './section.module.css'

const SectionDispatcher = forwardRef(
	({ setVisibleOnScroll, ...other }, ref) => {
		if (setVisibleOnScroll) {
			return <WithScrollCallbackSection {...{ ref }} {...other} />
		}
		return <Section {...{ ref }} {...other} />
	}
)

const WithScrollCallbackSection = (props) => {
	const containerReference = useRef()
	const showSection = useScrollWithReferenceCallback(
		containerReference,
		undefined,
		{
			transform: y => y + 150,
			substractWindowHeight: true
		}
	)
	return <Section ref={containerReference} {...{ showSection }} {...props} />
}

const Section = forwardRef(({
	withClassName, showSection = true, children, ...other
}, ref) => {
	const isMobile = useMediaQuery('(max-width: 600px)', { defaultMatches: true });
	const springProps = useSpring({
		...!isMobile && {
			opacity: showSection ? 1 : 0
		},
		pointerEvents: showSection ? 'all' : 'none',
		config: { duration: 1000 }
	})
	return (
		<animated.div
			className={cn(classes.container, withClassName)}
			style={{
				...(other && other.style),
				...springProps
			}}
			{...{ ref }}
			{...other}
		>
			{typeof children === 'function' ? children({ showSection }) : children}
		</animated.div>
	)
})

export default SectionDispatcher
