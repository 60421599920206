import React from 'react';

import cn from 'classnames';

import palette from '../../../utils/color_palette';

import classes from './card.module.css';

const Card = ({ component: Component = 'div', className, color, style, ...other }) => (
	<Component
		className={cn(classes.container, className)}
		style={{
			...style,
			...color && {
				backgroundColor: palette[color][500]
			}
		}}
		{...other}
	/>
);

export default Card;
