import SolutionsDropdown from './solutions_dropdown/solutions_dropdown';

import AccountIcon from '../../../../../assets/images/views/top_bar/account.svg';
import ContactIcon from '../../../../../assets/images/views/top_bar/contact.svg';

export default {
	solutions: {
		name: 'Solutions',
		dropdown: SolutionsDropdown
	},
	whoAreWe: {
		name: 'Qui sommes-nous ?',
		to: '/who-are-we'
	},
	news: {
		name: 'Actus',
		to: '/actus'
	},
	blog: {
		name: 'Blog',
		to: '/blog'
	},
	recruitment: {
		name: 'Jobs',
		to: '/jobs'
	},
	becomeDistributor: {
		name: 'Devenir distributeur',
		to: '/become-distributor',
		isExternalLink: true
	},
	contact: {
		name: 'Contact',
		to: '/contact',
		icon: ContactIcon,
		style: {
			marginLeft: 50
		},
		isExternalLink: true,
		hasDrawerSeparator: 'before'
	},
	myAccount: {
		name: 'Mon compte',
		to: 'https://pro.karnott.fr',
		icon: AccountIcon,
		isExternalLink: true,
		hasDrawerSeparator: 'after'
	}
};
