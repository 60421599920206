import React, { createContext, useCallback, useState } from 'react';

import TopBar from './top_bar/top_bar';
import Footer from './footer/footer';
import StickyItems from './sticky_items/sticky_items';

import classes from './main_layout.module.css';

const { Provider: MainLayoutProvider, MainLayoutConsumer } = createContext({});

const MainLayout = ({
	showFooter = true, footerProps, showStickyItems = false, stickyItemsProps, children
}) => {
	const [dimZones, setDimZones] = useState([]);
	const addDimZone = useCallback((dimZone) => {
		setDimZones([
			...dimZones,
			dimZone
		]);
	}, [dimZones]);
	return (
		<MainLayoutProvider
			value={{
				dimZones,
				addDimZone
			}}
		>
			<TopBar {...{ dimZones }} />
			{children}
			{showFooter && <Footer {...footerProps} />}
			{showStickyItems && <StickyItems {...stickyItemsProps} />}
		</MainLayoutProvider>
	);
}

export {
	MainLayout as default,
	MainLayoutConsumer
}
