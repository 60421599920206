import React from 'react';

import WithGradientLayout from '../../with_gradient_layout/with_gradient_layout';
import CreateDemoAccountSection from './create_demo_account_section/create_demo_account_section';
import TrustingCustomersListSection from './trusting_customers_section/trusting_customers_section';
import PrimaryIcons from './primary_icons/primary_icons';
import FooterLinks from './footer_links/footer_links';
import LanguageSelector from './language_selector/language_selector';
import AllRightsReserved from '../../../small_views/all_rights_reserved/all_rights_reserved';

import classes from './footer.module.css';

const Footer = ({ dismissedSections = [] }) => (
	<>
		{!dismissedSections.includes('create-demo-account') && <CreateDemoAccountSection />}
		{!dismissedSections.includes('trusting-customers-list') && <TrustingCustomersListSection />}
		{!dismissedSections.includes('bottom-section') && (
			<WithGradientLayout className={classes.gradientContainer}>
				<div className={classes.inGradientContent}>
					<PrimaryIcons />
					<FooterLinks />
					<div className={classes.bottomRow}>
						<AllRightsReserved className={classes.allRightsReserved} />
						<LanguageSelector className={classes.languageSelector} />
					</div>
				</div>
			</WithGradientLayout>
		)}
	</>
);

export default Footer;
