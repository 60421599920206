import React, { memo } from 'react'
import { Link } from 'gatsby'

import cn from 'classnames';

import Tag from '../../design_system/tag/tag'
import Card from '../../design_system/card/card'

import DefaultPreviewPicture from '../../../assets/images/views/important_actuality_popup/default_preview.jpg'

import classes from './important_actuality_popup.module.css'

const DEFAULT_IMPORTANT_ACTUALITY = Object.freeze({
	tags: [{ label: 'Event', color: '#34d1c5' }],
	title: 'Salon INNOVAGRI les 5 & 6 sept. Nous y serons !',
	preview: DefaultPreviewPicture
})

const ImportantActualityPopup = ({
	component: Component = 'div',
	className,
	cardClassName,
	actuality = DEFAULT_IMPORTANT_ACTUALITY,
	actualityId = 'actuality1',
	...other
}) => {
	const { tags, title, preview } = actuality
	return (
		<Component {...other}>
			<Link to={`/actu/${actualityId}`}>
				<Card className={cn(classes.container, className)}>
					<Tags {...{ tags }} />
					<div className={cn(classes.content, cardClassName)}>
						<div className={classes.titleContainer}>{title}</div>
						<div className={classes.previewContainer}>
							<img
								className={classes.preview}
								src={preview}
								alt={`Actualité importante - ${title} - Karnott`}
							/>
						</div>
					</div>
				</Card>
			</Link>
		</Component>
	)
}

const Tags = memo(({ tags }) =>
	tags
		.slice(0, 1)
		.map(tag => (
			<Tag
				className={classes.tag}
				key={`important_actuality_popup_tag_${tag.label}`}
				{...tag}
			/>
		)))

export default ImportantActualityPopup
