import React from 'react';
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import cn from 'classnames';

import GenericFixedBar from '../../../design_system/generic_fixed_bar/generic_fixed_bar';
import Links from './links/links';

import useScrollCallback from '../../../../hooks/use_scroll_callback';

import KarnottLogo from '../../../../assets/images/views/top_bar/logo-karnott.svg';

import classes from './top_bar.module.css';

const TopBar = ({ location }) => {
	const hasScrolled = useScrollCallback(10);
	let pathname = location && location.pathname;
	const forceSolidState = pathname && ['/blog-post/', '/actuality-article/']
		.some(root => pathname.startsWith(root));
	const isSolid = /* forceSolidState || hasScrolled; */ hasScrolled;
	return (
		<GenericFixedBar
			className={cn(classes.container, isSolid && classes.solidContainer)}
			fixPositionY={10}
		>
			<Link
				to="/"
				className={classes.logo}
			>
				<KarnottLogo />
			</Link>
			<Links />
		</GenericFixedBar>
	);
}

const WithLocationTopBar = props => (
	<Location>
		{({ location }) => (
			<TopBar
				{...props}
				{...{ location }}
			/>
		)}
	</Location>
);

export default WithLocationTopBar;
