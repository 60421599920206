const sections = Object.freeze({
	solutions: {
		name: 'Solutions',
		children: {
			agriculture: {
				text: 'Agriculture',
				link: '/solutions/agri',
				linkType: 'gatsbyLink'
			},
			eta: {
				text: 'ETA',
				link: '/solutions/eta',
				linkType: 'gatsbyLink'
			},
			viticulture: {
				text: 'Viticulture',
				link: '/solutions/viti',
				linkType: 'gatsbyLink'
			},
			cuma: {
				text: 'CUMA',
				link: '/solutions/cuma',
				linkType: 'gatsbyLink'
			},
			industrials: {
				text: 'Industriels',
				link: '/solutions/industriels',
				linkType: 'gatsbyLink'
			},
			thecounter: {
				text: 'Le compteur',
				link: '/solutions/lecompteur',
				linkType: 'gatsbyLink'
			}
		}
	},
	actualities: {
		name: 'Actus',
		children: {
			recruitments: {
				text: 'Recrutements',
				link: '/jobs',
				linkType: 'gatsbyLink'
			},
			blog: {
				text: 'Blog',
				link: '/blog',
				linkType: 'gatsbyLink'
			},
			actualities: {
				text: 'Actus',
				link: '/actus',
				linkType: 'gatsbyLink'
			}
		}
	},
	karnottAndI: {
		name: 'Karnott & moi',
		children: {
			myAccount: {
				text: 'Mon compte',
				link: 'https://karnott.fr'
			},
			whoAreWe: {
				text: 'Qui sommes-nous',
				link: '/who-are-we',
				linkType: 'gatsbyLink'
			},
			privacyPolicy: {
				text: 'Privacy Policy',
				link: 'https://karnott.fr'
			},
			termsAndConditions: {
				text: 'CGV / CGU',
				link: '/mentionslegales',
				linkType: 'gatsbyLink'
			}
		}
	}
});

export default sections;
