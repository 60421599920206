import { useCallback, useState, useEffect } from 'react';

import useScrollCallback from './use_scroll_callback';

const useScrollWithReferenceCallback = (reference, callback, options) => {
	const [positionY, setPositionY] = useState(null);
	const {
		transform,
		substractWindowHeight
	} = options;
	const checkAndSetNewPosition = useCallback(() => {
		const { offsetTop: top } = reference.current;
		let newPositionY = top;
		if (substractWindowHeight && window) {
			const { innerHeight } = window;
			newPositionY -= innerHeight;
		}
		if (typeof transform === 'function') {
			newPositionY = transform(newPositionY);
		}
		if (positionY !== newPositionY) {
			setPositionY(newPositionY);
		}
	}, [positionY, reference]);
	useEffect(() => {
		if (reference && reference.current) {
			checkAndSetNewPosition();
			window.addEventListener('resize', checkAndSetNewPosition);
			return () => window.removeEventListener('resize', checkAndSetNewPosition);
		}
	}, [reference]);
	return useScrollCallback(positionY, callback);
}

export default useScrollWithReferenceCallback;
