import React from 'react'

import cn from 'classnames'

import SimaStickerImage from '../../../assets/images/common/sima_sticker/sima_sticker_rotated.png'

import classes from './sima_sticker.module.css'

const SimaSticker = ({
	component: Component = 'img',
	children,
	className,
	...other
}) => {
	console.log(other)
	return (
		<Component
			className={cn(className, classes.container)}
			src={SimaStickerImage}
			alt="Sima Innovation Award"
			{...other}
		/>
	)
}

export default SimaSticker
