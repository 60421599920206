import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'

import cn from 'classnames'
import { animated, config, useSpring } from 'react-spring'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuIcon from '@material-ui/icons/Menu'

import Drawer from '../../../../design_system/drawer/drawer'
import RotatedArrow from '../../../../small_views/rotated_arrow/rotated_arrow'
import LanguageSelector from '../../footer/language_selector/language_selector'

import linksData from './links_data'

import classes from './links.module.css'

const Links = ({ location }) => {
	const inDrawer = useMediaQuery('(max-width: 1320px)', {
		defaultMatches: true
	})
	if (!linksData) {
		return null
	}
	const pathName = location && location.pathname
	const links = Object.entries(linksData).map(
		(
			[
				linkId,
				{
					name, to, icon, style, dropdown, isExternalLink, hasDrawerSeparator
				}
			],
			index
		) => {
			const isActive = pathName.startsWith(to)
			let content = null
			if (dropdown) {
				content = (
					<WithDropdownLink
						{...{
							name,
							dropdown,
							inDrawer
						}}
					/>
				)
			} else if (isExternalLink) {
				content = (
					<a href={to} target="_blank" rel="noreferrer noopener">
						{name}
					</a>
				)
			} else {
				content = <Link {...{ to }}>{name}</Link>
			}
			return (
				<div
					key={`link_${linkId}`}
					className={cn(
						classes.link,
						inDrawer && classes.inDrawerLink,
						isActive && classes.activeLink,
						hasDrawerSeparator
              && classes[`hasDrawerSeparator_${hasDrawerSeparator}`]
					)}
					{...{ style }}
					{...inDrawer && {
						'data-drawer-animated-index': index
					}}
				>
					{icon && <LinkIcon {...{ icon }} />}
					{content}
					{isActive && !inDrawer && <ActiveIndicator />}
				</div>
			)
		}
	)
	let content = null
	if (inDrawer) {
		content = <WithDrawer {...{ links }} />
	} else {
		content = links
	}
	return content
}

const WithDrawer = ({ links }) => {
	const [openDrawer, setDrawerOpenState] = useState(false)
	const setDrawerOpened = useCallback((event) => {
		event.preventDefault()
		event.stopPropagation()
		setDrawerOpenState(true)
	})
	const setDrawerClosed = useCallback(() => setDrawerOpenState(false))
	return (
		<>
			<Drawer anchor="right" open={openDrawer} onClose={setDrawerClosed}>
				<div className={classes.inDrawerLinksContainer}>
					{links}
					<LanguageSelector
						data-drawer-animated-index={links.length + 1}
						className={classes.languageSelector}
						withDropdownTextClassName={classes.withDropdownTextLanguageSelector}
					/>
				</div>
			</Drawer>
			<button className={classes.menuIconContainer} type="button">
				<MenuIcon onClick={setDrawerOpened} />
			</button>
		</>
	)
}

const WithDropdownLink = ({
	inDrawer,
	name,
	dropdown: DropdownComponent
}) => {
	const [anchorElement, setAnchorElement] = useState(false)
	const [open, setOpen] = useState(false)
	const toggleDropdown = useCallback(
		(event) => {
			console.log('Toggling');
			console.log({ open });
			event.stopPropagation();
			event.preventDefault();
			if (open) {
				setOpen(false)
			} else {
				setAnchorElement(event.currentTarget)
				setOpen(true)
			}
		},
		[anchorElement, open]
	);

	const setDropdownOpened = useCallback(
		(event) => {
			console.log('Opening.');
			setAnchorElement(event.currentTarget)
			setOpen(true)
		},
		[anchorElement, open]
	);

	const setDropdownClosed = useCallback(
		() => {
			console.log('Closing.');
			setOpen(false)
		},
		[anchorElement, open]
	);

	if (!name || !DropdownComponent) {
		return null
	}
	return (
		<div
			{...!inDrawer && {
				onMouseLeave: setDropdownClosed,
				onMouseEnter: setDropdownOpened
			}}
		>
			<div
				onClick={toggleDropdown}
				className={classes.nameArrowContainer}
			>
				{name}
				<RotatedArrow className={classes.arrow} rotationValue={open ? 90 : 0} />
			</div>
			<DropdownComponent
				{...inDrawer && {
					className: classes.inDrawerDropdown
				}}
				{...{ anchorElement, inDrawer, open }}
			/>
		</div>
	)
}

const LinkIcon = ({ className, icon: Icon }) => (
	<div className={cn(classes.iconContainer, className)}>
		<Icon />
	</div>
)

const ActiveIndicator = () => {
	const { opacity } = useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
		config: config.slow
	})
	return (
		<animated.div
			className={classes.activeIndicator}
			style={{
				opacity
			}}
		/>
	)
}

const WithLocationLinks = props => (
	<Location>
		{({ location }) => <Links {...{ location }} {...props} />}
	</Location>
)

export default WithLocationLinks
