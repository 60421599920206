import React, { useCallback } from 'react';

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import classes from './list_item.module.css';

const DEFAULT_SPRING_PROPS = Object.freeze({
	backgroundColor: 'rgba(0, 0, 0, 0)'
});

const ListItem = ({
	component: Component = animated.li,
	className,
	button,
	style,
	onMouseEnter,
	onMouseLeave,
	...other
}) => {
	const [springProps, setSpringProps] = useSpring(() => DEFAULT_SPRING_PROPS);
	const handleMouseEnter = useCallback((...parameters) => {
		if (typeof onMouseEnter === 'function') {
			onMouseEnter(...parameters);
		}
		setSpringProps(() => ({
			backgroundColor: 'rgba(0, 0, 0, .1)'
		}));
	}, [onMouseEnter]);
	const handleMouseLeave = useCallback((...parameters) => {
		if (typeof onMouseLeave === 'function') {
			onMouseLeave(...parameters);
		}
		setSpringProps(DEFAULT_SPRING_PROPS)
	}, [onMouseLeave]);
	return (
		<Component
			className={cn(classes.container, button && classes.button, className)}
			style={{
				...style,
				...springProps
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...other}
		/>
	)
}

export default ListItem;
