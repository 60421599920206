import React, { useCallback, memo } from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import Tag from '../../../../design_system/tag/tag';

import classes from './recommanded_small_article.module.css';

const DEFAULT_SPRING_PROPS = ({
	translation: 0,
	backgroundColor: 'rgba(0, 0, 0, 0)'
});

const FOCUSED_SPRING_PROPS = ({
	translation: -10,
	backgroundColor: 'rgba(0, 0, 0, .05)'
});

const RecommandedSmallArticle = ({
	id,
	type,
	article
}) => {
	if (!article || !id || !type) {
		return null;
	}
	const isBlog = type === 'blog';
	const {
		title,
		preview,
		tags
	} = article;
	const [springProps, setSpringProps] = useSpring(() => DEFAULT_SPRING_PROPS);
	const setLinkFocused = useCallback(() => setSpringProps(() => FOCUSED_SPRING_PROPS));
	const setLinkBlurred = useCallback(() => setSpringProps(() => DEFAULT_SPRING_PROPS));
	return (
		<Link
			className={classes.link}
			to={`/${isBlog ? 'blog-post' : 'actu'}/${id}`}
			onMouseEnter={setLinkFocused}
			onMouseLeave={setLinkBlurred}
			onFocus={setLinkFocused}
			onBlur={setLinkBlurred}
		>
			<animated.div
				className={cn(classes.container, classes[type])}
				style={{
					transform: springProps.translation.interpolate(value => `translate3d(0, ${value}px, 0)`),
					backgroundColor: springProps.backgroundColor
				}}
			>
				<div className={classes.previewContainer}>
					<img
						className={classes.preview}
						src={preview}
						alt={title}
					/>
				</div>
				<div className={classes.content}>
					{isBlog && <Tags {...{ tags }} />}
					<div className={classes.title}>
						{title}
					</div>
				</div>
			</animated.div>
		</Link>
	);
}

const Tags = memo(({ tags }) => {
	if (!tags) {
		return null;
	}
	return (
		<div className={classes.tagsContainer}>
			{tags.map(tag => (
				<Tag
					className={classes.tag}
					key={`tag_${tag.label}`}
					{...tag}
				/>
			))}
		</div>
	);
});

export default RecommandedSmallArticle;
