const solutionsLinksData = Object.freeze({
	agri: {
		name: 'AGRI',
		link: '/solutions/agri'
	},
	eta: {
		name: 'ETA',
		link: '/solutions/eta'
	},
	viti: {
		name: 'VITI',
		link: '/solutions/viti'
	},
	cuma: {
		name: 'CUMA',
		link: '/solutions/cuma'
	},
	indu: {
		name: 'Industriels',
		link: '/solutions/industriels'
	},
	thecounter: {
		name: 'Le compteur',
		link: '/solutions/lecompteur'
	}
});

export default solutionsLinksData;
