
import React, { useMemo, useState } from 'react';

import Carousel from '../../../design_system/carousel/carousel';
import RecommandedSmallArticle from './recommanded_small_article/recommanded_small_article';

import classes from './recommanded_articles.module.css';


const RecommandedArticles = ({ type, recommandedArticles }) => {
	const [currentView, setCurrentView] = useState(0);
	const views = useMemo(() => Object.entries(recommandedArticles)
		.map(([id, article], index) => (
			<div className={classes.recommandedArticleContainer}>
				<RecommandedSmallArticle
					key={`recommanded_small_article_${id}`}
					isHighlighted={currentView === index}
					{...{ id, type, article }}
					/>
			</div>
	)), [type, recommandedArticles, currentView]);
	return (
		<div className={classes.container}>
			<h2 className={classes.title}>
				{'Ces articles peuvent vous intéresser ;-)'}
			</h2>
			<div className={classes.carouselContainer}>
				<Carousel
					dots={false}
					slidesToShow={3}
					swipeToSlide={false}
					draggable={false}
					responsive={[
						{
							breakpoint: 1150,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 800,
							settings: {
								slidesToShow: 1,
								centerMode: true,
								centerPadding: 0
							}
						}
					]}
					>
					{views}
				</Carousel>
			</div>
		</div>
	)
};

export default RecommandedArticles;
