import React, {
	useRef
} from 'react';

import cn from 'classnames';

import useScrollCallback from '../../../hooks/use_scroll_callback';

import classes from './generic_fixed_bar.module.css';

const GenericFixedBar = ({
	className,
	placeholderClassName,
	fixPositionY: receivedFixPositionY,
	...other
}) => {
	const containerReference = useRef();
	const shouldBeFixed = useScrollCallback(receivedFixPositionY);
	return (
		<>
			{shouldBeFixed && (
				<div className={cn(classes.placeholder, placeholderClassName)} />
			)}
			<div
				ref={containerReference}
				className={cn(classes.container, className, shouldBeFixed && classes.fixedContainer)}
				{...other}
			/>
		</>
	)
}

export default GenericFixedBar
