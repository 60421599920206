import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import RotatedArrow from '../../../../small_views/rotated_arrow/rotated_arrow';
import LanguageSelectorDropdown from './language_selector_dropdown/language_selector_dropdown';

import classes from './language_selector.module.css';

const LanguageSelector = ({ className, withDropdownTextClassName, ...other }) => {
	const [open, setOpen] = useState(false);
	const [anchorElement, setAnchorElement] = useState(null);
	const handleMouseEnter = useCallback((event) => {
		setAnchorElement(event.currentTarget);
		setOpen(true);
	});
	const handleMouseLeave = useCallback(() => {
		setOpen(false);
	});
	return (
		<button
			{...{ className }}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			onBlur={handleMouseLeave}
			type="button"
			{...other}
		>
			<LanguageSelectorDropdown
				{...{
					anchorElement,
					open
				}}
			/>
			<div
				className={cn(classes.withDropdownText, withDropdownTextClassName)}
			>
				{'FR'}
				<RotatedArrow rotationValue={open ? -90 : 0} className={classes.arrow} />
			</div>
		</button>
	);
}

export default LanguageSelector;
