import React from 'react';

import cn from 'classnames';

import classes from './tag.module.css';

const Tag = ({
	component: Component = 'span',
	className,
	label,
	color,
	...other
}) => (
	<Component
		className={cn(classes.container, className)}
		style={{
			backgroundColor: color
		}}
		{...other}
	>
		{label}
	</Component>
);

export default Tag;
