import React, { useCallback, useState, memo } from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';
import { animated, useSpring, useTransition } from 'react-spring';

import PopperCard from '../../../../../design_system/popper_card/popper_card';
import List from '../../../../../design_system/list/list';
import ListItem from '../../../../../design_system/list_item/list_item';

import solutionsLinksData from './solutions_links_data';

import classes from './solutions_dropdown.module.css'

const SolutionsDropdown = ({ className, inDrawer, ...props }) => {
	if (!inDrawer) {
		return (
			<PopperCard
				className={cn(classes.container, className)}
				{...props}
			>
				<List>
					<Items />
				</List>
			</PopperCard>
		);
	}
	return <TreeItems {...props} />
}

const TreeItems = ({ open }) => {
	const items = open
		? Object.entries(solutionsLinksData)
		: [];
	const transitions = useTransition(items, item => `solutions_dropdown_tree_link_${item && item[0]}`, {
		from: {
			opacity: 0,
			translation: -10
		},
		enter: {
			opacity: 1,
			translation: 0
		},
		leave: {
			opacity: 0,
			translation: -10
		},
		trail: 80,
		unique: true
	});
	return (
		<List
			className={classes.treeContainer}
			style={{
				display: transitions.length ? 'block' : 'none'
			}}
		>
			{transitions.map(({ item, props: springProps, key }) => (
				<animated.div
					{...{ key }}
					style={{
						transform: springProps.translation.interpolate(value => `translate3d(${value}px, 0, 0)`),
						opacity: springProps.opacity
					}}
				>
					<Item
						{...item && item[1]}
					/>
				</animated.div>
			))}
		</List>
	);
}

const Items = memo(() => Object.entries(solutionsLinksData).map(([id, item]) => (
	<Item
		key={`solutions_dropdown_link_${id}`}
		{...item}
	/>
)));

const DEFAULT_LIST_ITEM_SPRING_PROPS = ({
	color: '#35d5c9'
});

const Item = ({ name, link }) => {
	const [listItemSpringProps, setListItemSpringProps] = useSpring(() => DEFAULT_LIST_ITEM_SPRING_PROPS);

	const handleMouseEnter = useCallback(() => setListItemSpringProps(() => ({
		color: '#fff'
	})));

	const handleMouseLeave = useCallback(() => setListItemSpringProps(() => DEFAULT_LIST_ITEM_SPRING_PROPS));
	return (
		<Link
			to={link}
		>
			<ListItem
				className={classes.listItem}
				button
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={listItemSpringProps}
			>
				<span className={classes.name}>
					{name}
				</span>
			</ListItem>
		</Link>
	)
}

export default SolutionsDropdown;
