import TwitterIcon from '../../../../../assets/images/social_icons/twitter.svg';
import FacebookIcon from '../../../../../assets/images/social_icons/facebook.svg';
import YoutubeIcon from '../../../../../assets/images/social_icons/youtube.svg';
import LinkedInIcon from '../../../../../assets/images/social_icons/linkedin.svg';

const socialIcons = Object.freeze({
	twitter: {
		icon: TwitterIcon,
		link: 'https://twitter.com/Karnott_fr'
	},
	facebook: {
		icon: FacebookIcon,
		link: 'https://www.facebook.com/Karnottfr/'
	},
	youtube: {
		icon: YoutubeIcon,
		link: 'https://www.youtube.com/channel/UCumGxE8ISy_R3M5qlMF31Vw'
	},
	linkedIn: {
		icon: LinkedInIcon,
		link: 'https://www.linkedin.com/company/karnott/'
	}
});

export default socialIcons;
