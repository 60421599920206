import React from 'react';
import { Link } from 'gatsby';

import cn from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import WithGradientLayout from '../with_gradient_layout/with_gradient_layout';
import Article from './article/article';
import RecommandedArticles from './recommanded_articles/recommanded_articles';

import Button from '../../design_system/button/button';

import classes from './blog_actuality_layout.module.css';

const BlogActualityLayout = ({ type, pageContext, children }) => {
	const { nextPostId, previousPostId } = pageContext;
	const recommandedArticles = type === 'actuality'
		? pageContext.recommandedActualityPosts
		: pageContext.recommandedBlogPosts;
	return (
		<div
			className={
				cn(
					classes.container,
					type === 'blog' && classes.blogContainer,
					type === 'actuality' && classes.actualityContainer
				)
			}
		>
			<WithGradientLayout
				className={classes.gradientLayout}
			/>
			<div className={classes.articleContainer}>
				<Article
					className={classes.article}
					title={pageContext.title}
					content={pageContext.content}
					preview={pageContext.preview}
					id={pageContext.id}
					tags={pageContext.tags}
					publishedAt={pageContext.publishedAt}
					{...{ type }}
				>
					<ActionsButtons {...{ type, nextPostId, previousPostId }} />
					<RecommandedArticles
						{...{ type, recommandedArticles }}
					/>
				</Article>
			</div>
			{children}
		</div>
	)
}

const ActionsButtons = ({ type, nextPostId, previousPostId }) => {
	const postPathName = type === 'blog' ? '/blog-post/' : '/actu/';
	const showShortLabel = useMediaQuery('(max-width: 500px)', { defaultMatches: true });
	return (
		<div className={classes.actionsButtons}>
			<div className={classes.buttonsContainer}>
				<GoBackToAllPosts {...{ type }} />
				{(nextPostId || previousPostId) && (
					<div className={classes.prevNextButton}>
						<PrevPostButton {...{ type, postPathName, previousPostId, showShortLabel }} />
						<NextPostButton {...{ type, postPathName, nextPostId, showShortLabel }} />
					</div>
				)}
			</div>
		</div>
	);
}

const GoBackToAllPosts = ({ type }) => {
	const label = type === 'blog'
		? 'Retour au blog'
		: "Retour à toute l'actu";
	const to = type === 'blog'
		? '/blog'
		: '/actus'
	return (
		<Link
			className={classes.buttonLink}
			{...{ to }}
		>
			<Button
				className={cn(classes.button, classes.goBackButton)}
				color="emerald"
				variant="outlined"
			>
				{label}
			</Button>
		</Link>
	);
}

const NextPostButton = ({ postPathName, nextPostId, showShortLabel }) => {
	if (!postPathName || !nextPostId) {
		return null;
	}
	const label = showShortLabel
		? 'Suiv. >'
		: 'Article suivant >';
	return (
		<Link
			className={classes.buttonLink}
			to={`${postPathName}/${nextPostId}`}
		>
			<Button
				className={classes.button}
				color="emerald"
				variant="contained"
			>
				{label}
			</Button>
		</Link>
	);
}

const PrevPostButton = ({ postPathName, previousPostId, showShortLabel }) => {
	if (!postPathName || !previousPostId) {
		return null;
	}
	const label = showShortLabel
		? '< Préc.'
		: '< Article précédent';
	return (
		<Link
			className={classes.buttonLink}
			to={`${postPathName}/${previousPostId}`}
		>
			<Button
				className={classes.button}
				color="primary"
				variant="contained"
			>
				{label}
			</Button>
		</Link>
	);
}

export default BlogActualityLayout;
