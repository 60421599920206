import React from 'react';

import cn from 'classnames';
import Slider from 'react-slick';

import classes from './carousel.module.css';

const carouselDefaultSettings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	swipeToSlide: true
};

const Carousel = ({ arrowClassName, children, ...other }) => (
	<Slider
		{...carouselDefaultSettings}
		mobileFirst
		nextArrow={<Arrow otherClassName={cn(classes.arrowRight, arrowClassName)} />}
		prevArrow={<Arrow reversed otherClassName={cn(arrowClassName)} />}
		cssEase="ease-in-out"
		{...other}
	>
		{children}
	</Slider>
)

const Arrow = ({
	otherClassName,
	className,
	reversed,
	style,
	...other
}) => (
	<div
		className={cn(classes.arrow, reversed && classes.reversed, otherClassName, className)}
		{...other}
	>
		{'>'}
	</div>
)

export default Carousel;
