import PostalAddressIcon from '../../../../../assets/images/views/footer/primary_icons/adressepostale.svg';
import EmailIcon from '../../../../../assets/images/views/footer/primary_icons/email.svg';
import PhoneIcon from '../../../../../assets/images/views/footer/primary_icons/telephone.svg';

const primaryIconsData = Object.freeze({
	postalAddress: {
		label: '225 rue des templiers, Lille',
		icon: PostalAddressIcon,
		link: 'https://www.google.com/maps/place/225+Rue+des+Templiers,+59000+Lille/@50.633179,3.0203829,17z/data=!3m1!4b1!4m5!3m4!1s0x47c2d55c50224b53:0x36b5634fcce759e7!8m2!3d50.6331756!4d3.0225716'
	},
	email: {
		label: 'contact@karnott.fr',
		icon: EmailIcon,
		link: 'mailto:contact@karnott.fr?subject=Demande de contact&body=Bonjour ! Je souhaite obtenir plus d\'informations sur vos services et produits !'
	},
	phoneIcon: {
		label: '+33 (0) 3 74 09 48 92',
		icon: PhoneIcon,
		link: 'tel:+33374094892'
	}
});

export default primaryIconsData;
