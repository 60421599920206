import React, { useEffect, useState } from 'react';

import { animated, config, useTransition } from 'react-spring';

import Section from '../../../../design_system/section/section';

import primaryIconsData from './primary_icons_data';

import classes from './primary_icons.module.css';

const PrimaryIcons = () => (
	<Section
		setVisibleOnScroll
		withClassName={classes.container}
	>
		{({ showSection }) => (
			<PrimaryIconsList
				{...{ showSection }}
			/>
		)}
	</Section>
);

const PrimaryIconsList = ({ showSection }) => {
	const [items, setItems] = useState();
	useEffect(() => {
		if (showSection && !items) {
			setItems(Object.entries(primaryIconsData))
		} else if (!showSection && items) {
			setItems(null);
		}
	}, [showSection, items]);
	const transitions = useTransition(items, entry => entry && entry[0], {
		unique: true,
		trail: 150,
		config: config.slow,
		from: {
			scale: 0.9,
			opacity: 0
		},
		enter: {
			scale: 1,
			opacity: 1
		},
		leave: {
			scale: 0.9,
			opacity: 0
		}
	});
	return transitions.map(({ item, props: { scale, ...springProps }, key }) => {
		if (!item) {
			return null;
		}
		const { icon: Icon } = item[1];
		return (
			<animated.a
				{...{ key }}
				className={classes.item}
				style={{
					transform: scale.interpolate(value => `scale3d(${value}, ${value}, ${value})`),
					...springProps
				}}
				href={item[1].link}
				target="_blank"
				rel="noreferrer noopener"
			>
				<Icon />
				<span>
					{item[1].label}
				</span>
			</animated.a>
		)
	})
}

export default PrimaryIcons;
