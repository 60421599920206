import React from 'react'

import cn from 'classnames'
import { animated, config, useSpring } from 'react-spring'

import classes from './section_title.module.css'

const SectionTitle = ({
	containerClassName,
	className,
	dismissUnderline,
	underlineClassName,
	children,
	...other
}) => {
	const { translation } = useSpring({
		translation: dismissUnderline ? -101 : 0,
		config: {
			...config.molasses,
			friction: 100
		}
	});
	return (
		<div className={cn(classes.container, containerClassName)}>
			<div
				className={cn(
					className,
					classes.title
				)}
				{...other}
			>
				{children}
				<animated.div
					className={cn(classes.underline, underlineClassName)}
					style={{
						transform: translation.interpolate(
							value => `translate3d(${value}%, 0, 0)`
						)
					}}
				/>
			</div>
		</div>
	)
}

export default SectionTitle
