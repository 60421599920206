import React, {
	useCallback, useEffect, useRef, useState
} from 'react';

import cn from 'classnames';
import { animated, config, useSpring } from 'react-spring';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import classes from './parallax_layer.module.css';

const DEFAULT_SPRING_PROPS = Object.freeze({
	translation: 0
});

const ParallaxLayer = ({
	className,
	style,
	scrollContainer,
	basePositionOnElement,
	getUpdateParallaxLayerHeight,
	customInterpolation,
	...other
}) => {
	const elementReference = useRef();
	const [springProps, setSpringProps] = useSpring(() => DEFAULT_SPRING_PROPS);
	const [positionY, setPositionY] = useState();
	const [elementHeight, setElementHeight] = useState(0);
	const isRunning = useRef(false);
	const interpolation = useCallback((value) => {
		if (typeof customInterpolation === 'function') {
			return customInterpolation(value);
		}
		return `translate3d(0, ${value}px, 0)`;
	}, [customInterpolation])
	const handleScroll = useCallback(() => {
		const { scrollY } = (scrollContainer || window);
		if (scrollY >= (positionY - (scrollContainer || window).innerHeight) && (positionY + elementHeight) >= scrollY) {
			setSpringProps(() => ({
				translation: (scrollY - positionY) / 6,
				config: config.slow,
				immediate: !isRunning.current
			}));
			if (!isRunning.current) {
				isRunning.current = true;
			}
		} else if (isRunning.current) {
			isRunning.current = false;
		}
	}, [positionY, elementHeight]);

	const getPositionY = useCallback(() => {
		if (!elementReference.current) {
			return;
		}
		const { y, height } = elementReference.current.getBoundingClientRect();
		setPositionY(y);
		setElementHeight(height);
	});

	useEffect(() => {
		(scrollContainer || window).addEventListener('scroll', handleScroll);
		return () => (scrollContainer || window).removeEventListener('scroll', handleScroll);
	}, [handleScroll]);
	useEffect(() => {
		getPositionY();
		(scrollContainer || window).addEventListener('resize', getPositionY);
		if (getUpdateParallaxLayerHeight) {
			getUpdateParallaxLayerHeight(getPositionY);
		}
		return () => (scrollContainer || window).removeEventListener('resize', getPositionY);
	}, []);
	return (
		<div
			className={cn(classes.container, className)}
			ref={elementReference}
			{...other}
		>
			<animated.div
				style={{
					transform: springProps.translation.interpolate(interpolation)
				}}
				{...other}
			/>
		</div>
	);
}

const HandleIsStaticParallaxLayer = ({
	className,
	staticClassName,
	staticMaxWidth = 550,
	...other
}) => {
	const isStatic = useMediaQuery(`(max-width: ${staticMaxWidth}px)`, { defaultMatches: true });
	if (isStatic) {
		return (
			<div className={cn(classes.container, className, staticClassName)} {...other} />
		);
	}
	return (
		<ParallaxLayer
			{...{ className }}
			{...other}
		/>
	);
}

export default HandleIsStaticParallaxLayer;
