import React from 'react';

import cn from 'classnames';
import { animated, useSpring } from 'react-spring';

import classes from './rotated_arrow.module.css';

const RotatedArrow = ({ rotationValue = '90', className }) => {
	const { rotation } = useSpring({
		rotation: rotationValue
	});
	return (
		<animated.div
			className={cn(classes.container, className)}
			style={{
				transform: rotation.interpolate(value => `rotate(${value}deg)`)
			}}
		>
			{'>'}
		</animated.div>
	);
}

export default RotatedArrow;
